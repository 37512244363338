import { FC, HTMLAttributes } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FooterSG } from '../FooterSG';
import { FooterTitleSG } from '../FooterTitleSG';
import { CountdownTimer } from '../CountdownTimer';
import { BuyButton } from '../BuyButton';
import {
    CartImage,
    BuyButtonText,
    ImageTextWrapper,
    AutoBuyImage,
    NextGameText,
    TicketCost,
    FlexWrapper,
} from '../BuyButton/mobile';
import { MessageBubble, MessageBubbleImage } from '../MessageBubble';
import { GamePart, GamePartDot } from '../GamePart';
import { NextGameSGProps } from './portrait';
import { useSoundHandler } from '../../hooks/use-sound-handler';

type NextGameSGLandscapeProps = HTMLAttributes<HTMLDivElement> &
    NextGameSGProps & {
        showFooter: boolean;
        onToggleFooter: () => void;
    };

const buttonDimension = css`
    width: 2.25rem;
    height: 2.25rem;
`;

const NextGameSGContainer = styled(FooterSG)`
    align-items: center;
    justify-content: space-evenly;
    padding: 0.25rem 3rem 0 0;
    width: 100%;
    left: 0;
    right: 0;
`;

const LabelTimerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 16.5rem;
    height: 2.5rem;
`;

const NextGameLabel = styled(FooterTitleSG)`
    font-size: 1.5rem;
`;

const TimerWrapper = styled(CountdownTimer)`
    font-size: 2.25rem;
`;

const BonusPartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    width: 8rem;
    height: 2.5rem;
    padding: 0.125rem 0.5rem;
    border-radius: 0.5rem;

    ${({ theme: { nextGameSG } }) => `
        border: solid 0.081rem ${nextGameSG.border};
        background-color: ${nextGameSG.background};
        box-shadow: 0 0.125rem 0 0 ${nextGameSG.boxShadow.first},
            inset 0 0.188rem 0 0 ${nextGameSG.boxShadow.second};
    `};
`;

const BonusWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const GamePartWrapper = styled(GamePart)`
    ${GamePartDot} {
        width: 0.75rem;
        height: 0.75rem;
    }
`;

const Moneybag = styled.img`
    width: 1.25rem;
    height: 1.25rem;
`;

const BonusPrize = styled.div`
    font-family: Signika-Bold;
    font-size: 1.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 75%;
    color: ${({ theme }) => theme.nextGameSG.color};
`;

const BuyButtonWrapper = styled(BuyButton)<{ size: number }>`
    width: 7rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    align-self: flex-start;

    ${FlexWrapper} {
        height: 2rem;
    }

    ${ImageTextWrapper} {
        padding-top: 0.125rem;
    }

    ${CartImage} {
        width: 0.875rem;
        height: 1.125rem;
    }

    ${BuyButtonText} {
        font-size: ${({ size }) => `${size}rem`};
        margin-top: 0;
        line-height: 1rem;
    }

    ${NextGameText} {
        font-size: 0.625rem;
    }
    ${TicketCost} {
        font-size: 0.75rem;
    }

    ${AutoBuyImage} {
        top: 0.25rem;
        right: -1rem;
        width: 1.875rem;
        height: 1.875rem;
    }
`;

export const MessageBubbleWrapper = styled(MessageBubble)`
    ${buttonDimension}

    ${MessageBubbleImage} {
        ${buttonDimension}
    }
`;

export const NextGameSGLandscape: FC<NextGameSGLandscapeProps> = ({
    countdownDate,
    isDisabled,
    autoBuy,
    gameParts,
    currentPart,
    prize,
    ticketPrice,
    mentions,
    showMessageBubble,
    handleClick,
    handleMentions,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { moneybag } = theme.images;
    const onClick = useSoundHandler(handleClick, 'click');

    return (
        <NextGameSGContainer {...props}>
            <LabelTimerContainer>
                <NextGameLabel text={t('modalPurchase.nextGame')} />
                <TimerWrapper to={countdownDate} />
            </LabelTimerContainer>
            <BonusPartContainer>
                <BonusWrapper>
                    <Moneybag src={moneybag} alt="Moneybag Image" />
                    <BonusPrize>{prize}</BonusPrize>
                </BonusWrapper>
                <GamePartWrapper
                    gameParts={gameParts}
                    currentPart={currentPart}
                />
            </BonusPartContainer>
            <BuyButtonWrapper
                size={t('buyBtn.buy.landscape.size')}
                cardCost={ticketPrice}
                isDisabled={isDisabled}
                autoBuy={autoBuy}
                handleClick={onClick}
            />
            {showMessageBubble && (
                <MessageBubbleWrapper
                    mentions={mentions}
                    handleClick={handleMentions}
                />
            )}
        </NextGameSGContainer>
    );
};
