import { useHookstate } from '@hookstate/core';
import { FC } from 'react';
import { TicketsPurchasedPanel as TicketsPurchasedPanelComponent } from '../../components/TicketsPurchasedPanel';
import { account, meta, next, autoBuy } from '../../stores/backend';
import { chatMeta } from '../../stores/chat';
import { useAccount } from '../../hooks/use-account';

export const TicketPurchasedPanel: FC = () => {
    const $account = useHookstate(account);
    const $meta = useHookstate(meta);
    const $next = useHookstate(next);
    const $chatMeta = useHookstate(chatMeta);
    const $autoBuy = useHookstate(autoBuy);

    const { wagered } = useAccount($next, $meta, $account);

    const to = new Date($meta.timer.date.value);

    const handleClick = () => {
        $meta.modals.buy.set(true);
    };

    return (
        <TicketsPurchasedPanelComponent
            to={to}
            wagered={wagered}
            maxTickets={$next.game_cards_max.value}
            freeCards={$next.game_free_cards.value || 0}
            totalPromoCards={$next.game_total_promo_cards.value || 0}
            chatOpen={$chatMeta.chatToggle.value}
            autoBuy={$autoBuy.activation.value}
            ticketsPurchased={
                Object.keys($next.player_cards.value || {}).length
            }
            handleClick={handleClick}
        />
    );
};
