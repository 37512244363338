import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getQuery } from './query';

export const DEFAULT_LANGUAGE = 'en';

export const getTranslation = (lng: string): Promise<Record<string, string>> =>
    import(`../assets/locales/${lng}.json`).then((res) => res.default);

export const getComposedTranslation = async (lng: string) => {
    const [tlEn, tlComposed] = await Promise.all([
        getTranslation('en'),
        lng === 'en' ? {} : getTranslation(lng),
    ]);

    return {
        ...tlEn,
        ...tlComposed,
    };
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: DEFAULT_LANGUAGE,
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        detection: {
            lookupQuerystring: 'LANGUAGE',
        },
        backend: {
            loadPath: '{{lng}}',
            request: (_options, _url, _payload, callback) => {
                const { LANGUAGE: lng } = getQuery();
                const language = lng || DEFAULT_LANGUAGE;

                try {
                    getComposedTranslation(language).then((data) => {
                        callback(null, {
                            data: JSON.stringify(data),
                            status: 200,
                        });
                    });
                } catch (error) {
                    callback(error, {
                        data: JSON.stringify(error),
                        status: 500,
                    });
                }
            },
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
