import { FC, HTMLAttributes } from 'react';
import { isMobile } from 'react-device-detect';
import { BuyButtonMobile } from './mobile';
import { BuyButtonDesktop } from './desktop';

export type WithTotalProps = {
    withTotal?: boolean;
};

export type BuyButtonRawProps = WithTotalProps & {
    total?: string;
    tickets?: number;
    autoBuy?: boolean;
    cardCost?: string;
    isDisabled: boolean;
    handleClick: () => void;
};

export type BuyButtonProps = HTMLAttributes<HTMLButtonElement> &
    BuyButtonRawProps;

export const BuyButton: FC<BuyButtonProps> = ({
    withTotal,
    total,
    tickets,
    autoBuy,
    cardCost,
    isDisabled,
    handleClick,
    ...props
}) => {
    const Component = isMobile ? BuyButtonMobile : BuyButtonDesktop;

    return (
        <Component
            withTotal={withTotal}
            total={total}
            tickets={tickets}
            autoBuy={autoBuy}
            isDisabled={isDisabled}
            cardCost={cardCost}
            handleClick={handleClick}
            {...props}
        />
    );
};
