import { FC, useMemo, useCallback } from 'react';
import { useState } from '@hookstate/core';
import { isMobile } from 'react-device-detect';
import { CurrentGamePanelMobile } from '../../components/CurrentGamePanel/mobile';
import { CurrentGamePanelDesktop } from '../../components/CurrentGamePanel/desktop';
import { Part } from '../../components/GamePart';
import { useGameType } from '../../hooks/use-game-type';
import { useJackpot } from '../../hooks/use-jackpot';
import { useGamePart } from '../../hooks/use-game-part';
import { useCountdown } from '../../hooks/use-countdown';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { useSoundHandler } from '../../hooks/use-sound-handler';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import {
    current,
    meta,
    next,
    calls,
    autoBuy,
    screenConfig,
} from '../../stores/backend';
import { useTicketPrice } from '../../hooks/use-ticket-price';

export const CurrentGamePanel: FC = () => {
    const $current = useState(current);
    const $next = useState(next);
    const $calls = useState(calls);
    const $autoBuy = useState(autoBuy);
    const $meta = useState(meta);
    const {
        screenConfig: { display },
    } = useState(screenConfig);

    const { seconds } = useCountdown(new Date($meta.timer.date.value));
    const bought = useBoughtTickets($current);

    const game = bought ? $current : $next;
    const playerCards = game.game_cards.value || 0;
    const players = game.game_players.value || 0;

    const gameType = useGameType(game);
    const ticketPrice = useTicketPrice($next);
    const gameCurrency = $next.game_currency.value;
    const formatCurrency = useFormatCurrency(game, $meta);
    const formatCurrencyNext = useFormatCurrency($next, $meta);
    const { part, prize, prizeString, patternName, allGameParts } = useGamePart(
        game,
        $meta
    );

    const formattedPrize = formatCurrency(
        prizeString[$calls.length - 1],
        game.game_currency.value
    );
    const gamePrize = prizeString.length > 1 ? formattedPrize : prize;

    const gameParts = useMemo(
        () => Object.keys(allGameParts).length,
        [allGameParts]
    );

    const parts = useMemo(
        () =>
            Object.values(game.game_parts.value || {}).map((part) => ({
                patterns: part.pat_code || [[]],
                matches: part.num_matches || 1,
            })),
        [game.game_parts.value]
    );

    const jackpot = useJackpot(game, $meta);

    const onInfoBox = useCallback(() => {
        $meta.modals.gameInfo.set(true);
    }, [$meta.modals.gameInfo]);

    const onBuy = () => {
        $meta.modals.buy.set(true);
    };

    const $onBuy = useSoundHandler(onBuy, 'click');

    const Component = isMobile
        ? CurrentGamePanelMobile
        : CurrentGamePanelDesktop;

    return (
        <Component
            {...jackpot}
            onInfoBox={onInfoBox}
            onBuy={$onBuy}
            seconds={seconds}
            gameTitle={game.game_desc.value}
            gameType={gameType}
            gameParts={gameParts as Part}
            currentPart={part}
            patternName={patternName}
            gamePlayers={players}
            gameCards={playerCards}
            parts={parts}
            patternType={gameType}
            gamePrize={gamePrize}
            gameLinked={game.game_linked?.value}
            gameContinued={game.game_continued?.value}
            showTotalTickets={display.showTotalTickets.value}
            autoBuy={$autoBuy.activation.value}
            ticketPrice={formatCurrencyNext(ticketPrice, gameCurrency)}
        />
    );
};
